import React from 'react';

const MainWhy = () => {
    return (
        <section id="content-2" className="row mb-3">
			<div className="col-md-12 mb-5">
				<h3 className="ctx-title text-center mb-3">Miért érdemes csatlakozni hozzánk?</h3>
				<div className="row">
					<div className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
								<i className="fa-solid fa-check-circle fa-3x text-success mb-3"></i>
								<h4 className="card-title mb-3">Fejlett Logbook</h4>
								<p className="ctx-text">
									Egyedülálló log-rendszerünk segítségével akkor is tudsz rögzíteni QSO logot, ha épp nem vagy online. A rendszer rögzíti a logjaid, 
									és szinkronizáld fel akkor, amikor újra online vagy!
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
								<i className="fa-light fa-chart-network fa-3x text-success mb-3"></i>
								<h4 className="card-title mb-3">Kiterjedt hálózat</h4>
								<p className="ctx-text">
									Rádiózz, vagy üzenj társadnak, hogy szeretnél összeköttetést létesíteni! Ehhez segítségül tudod hívni a <i>Közösség</i> aloldalunkat, 
									ahol, akár SMS üzenetben is tudod értesíteni a társaid!
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
								<i className="fa-solid fa-award fa-3x text-success mb-3"></i>
								<h4 className="card-title mb-3">Versenyek és Díjak</h4>
								<p className="ctx-text">
									Tervezünk versenyeket létrehozni, ahol <i>nem mindig az összeköttetések száma</i> a mérvadó. Gyere, és próbáld ki magad. 
									Ha <b>nincs rádiód</b>, mi <b>segítünk</b> kiválasztani egyet, és még Kedvezményeket<sup>2</sup> is szerezhetsz partnereinknél.
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-12 col-xs-12 mb-3">
						<div className="card">
							<div className="card-body">
								<i className="fa-regular fa-diploma fa-3x text-success mb-3"></i>
								<h4 className="card-title mb-3">Oktatás és fejlődés</h4>
								<p className="ctx-text">
									Az <b>1x1</b> oldalunkon összegyűjtjük azokat a legfontosabb és rádiózáshoz kapcsolódó anyagokat,
									amely segítenek Neked az összeköttetések létrehozásában, de <i>akár</i> egy NMHH vizsgához is hozzájárulhatnak!
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>						
		</section>
    );
};

export default MainWhy;