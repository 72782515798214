import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import variables from '../consts';

const Hero = () => {
    return (
        <div className="container-fluid">
            <header className="row mb-3 text-center header-section">
                <div className="col-12 header-wrapper"></div>
                <div className="col-12 header-content d-flex align-items-center justify-content-center">
                    <div className="d-block">
                        <h2>446.HU</h2>
                        <h3 className="mb-3">A Magyar PMR közösség</h3>
                        <ButtonGroup aria-label="Hero Buttons">
                            <a href={`https://auth.446.${localStorage.getItem('domainExt')}/register`} target="_self" className="btn btn-success">Regisztrálok</a>
                        </ButtonGroup>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Hero;