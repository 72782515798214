import { useEffect, useLayoutEffect, useState } from 'react';
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import MainIsmerteto from './Components/MainIsmerteto';
import MainRegister from './Components/MainRegister';
import MainWhy from './Components/MainWhy';
import Navbar from './Components/Navbar';
import LoadingIndicator from './Components/LoadingIndicator';
import Ads from './Components/Ads';

function App() {
  const [init, setInit] = useState(false);

  useLayoutEffect(() => {
    if (!init) {
      const domain = window.location.hostname;
      let staticDomain;
      let domainExt;
      let cssFiles = {
        bootstrap: 'bootstrap.min.css',
        fapro: 'fa.all.min.pro.css',
        pub: 'pub.css'
      }
      if (domain === 'localhost' || domain === '446.local') {
        staticDomain = 'http://static.446.local/';
        domainExt = 'local';
      } else if (domain === '446.hu') {
        staticDomain = 'https://static.446.hu/';
        domainExt = 'hu';
      }

      Object.entries(cssFiles).map(([key, value]) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `${staticDomain}css/${value}`;
        document.head.appendChild(link);
      });

      const icon = document.createElement('link');
      icon.rel = 'icon';
      icon.href = `${staticDomain}images/favicon.png`;
      icon.type = 'image/png';
      document.head.appendChild(icon);

      localStorage.setItem('domain', staticDomain);
      localStorage.setItem('domainExt', domainExt);
      
      setInit(true);
    };
  }, []);

  

  return (
    <>
      <LoadingIndicator initialized={init} />
      <Navbar />
      <Hero />
      <main className="container">
        <MainIsmerteto />
        <Ads />
        <MainWhy />
        <MainRegister />
        <Ads />
      </main>
      <Footer />
    </>

  );
}

export default App;
