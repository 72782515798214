import React, { useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';

const LoadingIndicator = ({ initialized }) => {
    return !initialized && (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,1)",
            position: "fixed",
            zIndex: "10002"
          }}>
            <TailSpin color="#ff0000" height={100} width={100} />
          </div>
    );
};

export default LoadingIndicator;