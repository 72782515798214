import React from 'react';

const Footer = () => {
    return (
        <footer className="container-fluid footer">
            <div className="row">
                <div className="col-md-12 text-center ftr-ctx-text">
                    Minden jog fenntartva &copy; 2023-2024 - <a href="#">446.HU - A Magyar PMR közösség</a>.
                </div>
                <div className="col-md-12 text-center ftr-ctx-social">
                    <a href="https://facebooko.com" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;