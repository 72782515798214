import React from 'react';

const MainIsmerteto = () => {
    return (
        <>
        <section id="content" className="row mb-3">
			<div className="col-md-6 col-sm-12">
				<h3 className="ctx-title">Ismertető</h3>
				<p className="ctx-text">Jelenleg egy olyan weboldalra tévedtél, ami a PMR (Public Mobile Radio), azaz Személyes használatú rádiózással foglalkozik, ennek ellenére szívesen látunk "Hívójeles" rádiósokat is.</p>
				<p className="ctx-text">Lehetőséget szeretnénk adni egy kiterjedt rádiós közösségnek, ahol nem számít az NMHH vizsga, ahol Mindenki barát. Ezen felül reméljük, hogy minél több új tag csatlakozik a közösségünkhöz és népszerűsíteni tudjuk a rádiózást, kortól és nemtől függetlenül, légy itthon, vagy hazánk határain túl!</p>
				<p className="ctx-text">Az oldalra történő regisztrációkor a webhely segít neked a hívójel kiosztásában és elmagyarázza, milyen adatokra, miért van szükség. Hidd el, mindennek oka van!. Emellett az oldalon rögzítheted a kapcsolatfelvételt QSO és a kitelepüléseid is. Az oldalon egy komplex térkép alkalmazás is fut, (Vendégként is bepillantást nyerhetsz), ahol láthatod az eseményeket, kitelepüléseket, átjátszókat, stb.</p>
				<p className="ctx-text">Nem titkolt célunk, hogy érkezzen Androidra és iOSra egy app, amivel elérheted a rendszerünket telefonról, és könnyedén rögzítheted a QSO-t.</p>
			</div>
			<div className="col-md-6 align-self-center col-sm-12">
				<img alt="antenna-kep" className="img-responsive" src={`${localStorage.getItem('domain')}/images/hamradio_1.jpg`} />
			</div>
		</section>
        <section id="content-1" className="row mb-3">
            <div className="col-md-6 align-self-center text-center col-sm-12 order-md-1 order-sm-2">
                <img alt="446.hu logo" className="img-responsive" src={`${localStorage.getItem('domain')}/images/446logo.png`} />
            </div>
            <div className="col-md-6 col-sm-12 order-md-2 order-sm-1">
                <h3 className="ctx-title">Céljaink</h3>
                <p className="ctx-text">Ha már ide tévedtél, és tudod, mi az a 446, akkor azt is tudod, mi lehet a célkitűzésünk, de azért itt van néhány mondatba szedve.</p>
                <p className="ctx-text">Legfontosabb, hogy a már megszerzett közösséget összefogjuk és egy IT hátteret biztosítsunk, azoknak, akik szeretik logolni, hogy mikor, és kivel sikerült kapcsolatot felvenniük és összefogjuk a "Hívójeles" és nem hívójeles rádiósokat egymáshoz.</p>
                <p className="ctx-text">Az biztos, hogy nincs és nem is lesz könnyű dolgunk, hiszen egyes szokásokat megváltoztatni igen nehéz, de törekszünk arra, hogy ez a közösség is fejlődjön, ahogy minden más.</p>
                <p className="ctx-text">Szeretném ha nem úgy kéne vadászni az állomásokat, mintha mamutra akarnék lőni. Szerintem ezzel mindenki így van. Célunk az is, hogy elérjük, hogy ne csak üljünk a rádió mellett és várjuk a bejelentkezéseket, hanem, hogy hívjunk is. Szóval törjük meg a csendet, ragadjuk meg a mikrofont, és szóljunk bele.</p>
                <p className="ctx-text">Rádiót a kézbe, hangokat az éterbe; szóljon a Rock'n'Roll!</p>
            </div>			
        </section>
        </>
    );
};

export default MainIsmerteto;