import React from 'react';
import variables from '../consts';

const MainRegister = () => {
    return (
        <section id="content-3" className="row mb-3">
			<div className="col-md-6 align-self-center text-center col-sm-12 order-md-1 order-sm-2">
				<img className="img-responsive" src={`${localStorage.getItem('domain')}/images/qso_log_screen.jpg`} />
			</div>
			<div className="col-md-6 col-sm-12 order-md-2 order-sm-1">
				<h3 className="ctx-title">Csatlakozz hozzánk!</h3>
				<p className="ctx-text">Légy részese egy igazi generációs ugrásnak! Csatlakozz még ma, hogy a Jelenléteddel segítsd a rádiós közösség népszerűsítését!</p>
				<p className="ctx-text">Hozd létre a fiókod, létesíts összeköttetést és <b>Oszd meg barátaiddal</b>, hogy Ők is részesei legyenek az élménynek!</p>
				<p className="ctx-text">Generálj minél több QSO-t a versenyek alkalmával és <b>szerezz ajándékokat</b>!</p>
				<p className="ctx-text">
					<a href={`https://auth.446.${localStorage.getItem('domainExt')}/register`} target="_self" className="btn btn-success">
						<i className="fa-solid fa-user"></i> 
						Regisztrálok!
					</a>
				</p>
			</div>			
		</section>
    );
};

export default MainRegister;