import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { default as BSNavbar} from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Navbar = () => {
    return (
        <BSNavbar className="bg-body-tertiary">
            <Container>
                <BSNavbar.Collapse id="basic-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href={`http://auth.446.${localStorage.getItem('domainExt')}/login`}>Belépés</Nav.Link>
                    </Nav>
                </BSNavbar.Collapse>
            </Container>
        </BSNavbar>
    );
};

export default Navbar;